import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Media, Col, Row, Button } from "react-bootstrap"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import "./Section4.scss"

export default function Section4(props) {
  const s4imgQuery = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "section-4" }
          extension: { eq: "png" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(sizes: "400", width: 400)
            }
          }
        }
      }
    }
  `)
  let counter = 0
  let images = []
  s4imgQuery.allFile.edges.map(index => {
    images[counter] = getImage(index.node)
    counter++
    //console.log(getImage(index.node))
  })

  let imgToDisplay = images[0]
  if (props.imageOption) {
    imgToDisplay = images[Number(props.imageOption)]
  }

  return (
    <Container
      fluid
      className="firstcont py-xl-6 section-4-bg d-flex align-items-center"
    >
      <Container fluid="xl" className=" py-xl-6 my-auto ">
        <Row className="justify-content-center">
          <Col
            lg={{ order: "last", span: 8 }}
            md={{ order: "first" }}
            className="pl-xl-4 text-center "
          >
            <h1 className="pt-3 h1-header text-white">
              What is a line of credit?
            </h1>
            <p>
              A line of credit is a flexible loan that you can access in varying
              amounts up to your approved credit limit available whenever you
              need it. Interest will only be charged on each draw. You can
              choose to repay it immediately or over time.
            </p>
            <a className="p-3 mt-2 btn btn-primary" href="/how-it-works/#top">
              Learn More
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
