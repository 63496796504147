import React, { useState } from "react"
import { Container, Button, Col, Row } from "react-bootstrap"
import Typewriter from "typewriter-effect"
import PreApplicationSlider2 from "../../../../components/Extras/PreApplicationSlider/PreApplicationSlider2"
import "./Section1.scss"

export default function Section1() {
  const displaySliderForm = () => {
    window.location.href = "https://application.pavecredit.com/"
    return true

    document.querySelector("div.pre-application-slider").style.display = "block"
    document.body.style.overflow = "hidden"
    document.querySelector("html").scrollTop = window.scrollY

    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop)
    }
  }
  return (
    <>
      <div className="diagonal-container">
        <Container fluid className="top-home home-page-1 px-0 ">
          <PreApplicationSlider2 />
        </Container>{" "}
      </div>
    </>
  )
}
