import React, { Component } from "react"
import Slider from "react-slick"
import "../../../../node_modules/slick-carousel/slick/slick.css"
import "../../../../node_modules/slick-carousel/slick/slick-theme.css"
import "./PreApplicationSlider.scss"
import { Container, Button, Col, Row, Form, Dropdown } from "react-bootstrap"
import prettyMoney from "pretty-money"

let currentStep = null
let extraValidations = "validateStep" + currentStep

let validatedAmouunt = ""
let validatedWhy = ""
let validatedState = ""

const prettyDollarConfig = {
  currency: "$",
  position: "before",
  spaced: false,
  thousandsDelimiter: ",",
}
function roundUpLineAmount(value) {
  let result = 0
  let newVal = 0

  if (Math.round(value / 50) * 50 < value) {
    newVal = parseInt(value) + 23
    console.log(newVal)
    result = Math.round(newVal / 50) * 50
    console.log(result)
  } else {
    result = Math.round(value / 50) * 50
  }

  return result
}
function validateStep1() {
  const amountInput = document.getElementById("amountInput")
  console.log(document.getElementById("amountInput"))
  let formatedValue = amountInput.value.replace(/[^0-9]/g, "")
  amountInput.value = formatedValue
  if (formatedValue.length >= 3) {
    console.log(formatedValue.length)
    console.log(formatedValue)

    amountInput.value = prettyMoney(
      prettyDollarConfig,
      roundUpLineAmount(formatedValue)
    )
    //return true

    if (formatedValue >= 400 && formatedValue <= 3000) {
      document
        .getElementById("amountInput")
        .closest(".container")
        .classList.remove("invalid")
      validatedAmouunt = formatedValue
      console.log(validatedAmouunt)
      return true
    } else {
      document
        .getElementById("amountInput")
        .closest(".container")
        .classList.add("invalid")
      return false
    }
  } else {
    document
      .getElementById("amountInput")
      .closest(".container")
      .classList.add("invalid")
    return false
  }
}

function delayFn(callback, ms) {
  var timer = 0
  return function () {
    var context = this,
      args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      callback.apply(context, args)
    }, ms || 0)
  }
}

let delayVar = delayFn(function (e) {
  validateStep1()
}, 500)

function validateStep2(why) {
  validatedWhy = why.toLowerCase().replace(/\s+/g, "_")
  console.log(validatedWhy)
  return true
}

function validateStep3(st) {
  validatedState = st
}

export default class SimpleSlider2 extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  componentDidMount() {
    var styles = {
      "background-image":
        "url(/static/line-of-credit-bg-d99931977e71423532a45176b21b685c.png)",
      "background-size": "cover",
      "background-repeat": "no-repeat",
    }
    var obj = document.querySelector("#preApplicationSlide1").parentElement
      .parentElement
    Object.assign(obj.style, styles)

    var obj1 = document.querySelector("#preApplicationSlide2").parentElement
      .parentElement
    Object.assign(obj1.style, styles)

    var obj2 = document.querySelector("#preApplicationSlide3").parentElement
      .parentElement
    Object.assign(obj2.style, styles)

    /*
    document.querySelector(
      "#preApplicationSlide1"
    ).parentElement.parentElement.style.backgroundImage =
      "url(/static/line-of-credit-bg-d99931977e71423532a45176b21b685c.png)"
   
    document.querySelector(
      "#preApplicationSlide2"
    ).parentElement.parentElement.style.backgroundImage =
      "url(/static/line-of-credit-bg-d99931977e71423532a45176b21b685c.png)"
*/
    let price = prettyMoney({ currency: "EUR" }, 10000)
    console.log(price)
    const args = {
      afterFormat(e) {
        console.log("afterFormat", e)
      },
      allowNegative: false,
      beforeFormat(e) {
        console.log("beforeFormat", e)
      },
      negativeSignAfter: false,
      prefix: "$",
      suffix: "",
      fixed: true,
      fractionDigits: 2,
      decimalSeparator: ".",
      thousandsSeparator: ",",
      cursor: "move",
    }
    //const input = SimpleMaskMoney.setMask("#amountInput", args)

    // This method return value of your input in format number to save in your database
    //input.formatToNumber()
  }

  next(e) {
    var t = 1366
    var i = 1
    document.getElementsByClassName(
      "diagonal-container"
    )[0].style.backgroundPosition = t * i + "px"
    currentStep = e.target.closest(".container").getAttribute("data-step")
    if (!currentStep) return false
    if (currentStep == 0) {
      //document.getElementsByClassName("diagonal-1")[0].classList.add("animated")
    }
    if (currentStep == 1) {
      let result = validateStep1()
      if (!result) return false
    }
    if (currentStep == 2) {
      //let result = validateStep2()
      //if (!result) return false
    }

    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  CloseSlider() {
    window.onscroll = function () {}
    document.querySelector("div.pre-application-slider").style.display = "none"
  }

  handleKeyUp() {
    delayVar()
  }

  updateDropDown(e) {
    document.getElementById("dropdown-basic").innerHTML = e.substr(1)
    validateStep2(e.substr(1))
  }
  updateDropDown2(e) {
    document.getElementById("dropdown-basic2").innerHTML = e.substr(4)
    //validateStep3(e.substr(1))
    validatedState = e.split("#", 2)[1].split("-")[0]
  }

  redirection() {
    let redirectURL =
      "https://application.pavecredit.com/?amount=" +
      validatedAmouunt +
      "&why=" +
      validatedWhy +
      "&st=" +
      validatedState
    /*
    let endpoint = window.location.href

    if (endpoint.indexOf("dev.") >= 0) {
      redirectURL = "https://application.pavecredit.com/"
    } */

    window.location.href = redirectURL
  }
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: false,
      swipe: false,
      arrows: false,
    }

    // configuration

    return (
      <div className=" pre-application-slider ">
        <Slider ref={c => (this.slider = c)} {...settings}>
          <Container
            className="typewriter-col slide-1"
            data-step="0"
            id="preApplicationSlide1"
          >
            <span className="  pt-4  text-white text-center">
              No More <br /> Rocky Roads
            </span>
            <Row className="text-center mt-3">
              <Col
                xs={{ order: "first", span: 12 }}
                className="text-right px-4 mb-5"
              >
                <Col xs={12} lg={12} className="mx-auto">
                  <p className="col-lg-8 pt-3 mx-auto text-center">
                    Pave Credit provides a fast, easy, and modern way of
                    borrowing money with a line of credit that provides a solid
                    financial foundation.
                  </p>
                </Col>
              </Col>

              <Col className="text-center">
                <Button
                  onClick={this.next}
                  variant="primary"
                  className="w-50 py-2 pre-application-start"
                >
                  Start Application
                </Button>
              </Col>
            </Row>
          </Container>
          <Container
            className="slide-2"
            data-step="1"
            id="preApplicationSlide2"
          >
            <h1 className="h1-header pt-4  text-white text-center">
              How much would you like to borrow?
            </h1>
            <Row className="text-center mt-5">
              <Col className="text-right">
                <Button
                  onClick={this.previous}
                  variant="primary"
                  className="w-50 py-2 prev-btn"
                >
                  Prev
                </Button>
              </Col>
              <Col
                xs={{ order: "first", span: 12 }}
                className="text-right px-4 mb-5"
              >
                <Col xs={12} lg={7} className="mx-auto">
                  <p
                    className="line-amount-feedback"
                    style={{ color: "white" }}
                  >
                    Please use an amount between $400 and $3,000 (in $50
                    increments only):
                  </p>
                  <Form.Control
                    type="text"
                    className="w-100 m-auto text-center"
                    placeholder="$"
                    id="amountInput"
                    defaultValue=""
                    onKeyUp={this.handleKeyUp}
                  />
                  <p style={{ color: "white" }} className="text-center mt-2">
                    We will round it up for you.
                  </p>
                </Col>
              </Col>

              <Col className="text-left">
                <Button
                  onClick={this.next}
                  variant="primary"
                  className="w-50 py-2"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Container>
          <Container
            className=" slide-3"
            data-step="2"
            id="preApplicationSlide3"
          >
            <h1 className="h1-header pt-4  text-white text-center">
              What do you need it for?
            </h1>
            <Row className="text-center mt-5">
              <Col className="text-right">
                <Button
                  onClick={this.previous}
                  variant="primary"
                  className="w-50 py-2 prev-btn"
                >
                  Prev
                </Button>
              </Col>
              <Col
                xs={{ order: "first", span: 12 }}
                className="text-right mb-5"
              >
                <Col lg={7} className="mx-auto">
                  <Dropdown
                    className="text-center"
                    onSelect={this.updateDropDown}
                  >
                    <Dropdown.Toggle
                      defaultValue=""
                      variant="success"
                      id="dropdown-basic"
                    >
                      Select one
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#Car Repair">
                        Car Repair
                      </Dropdown.Item>
                      <Dropdown.Item href="#Medical Bills">
                        Medical Bills
                      </Dropdown.Item>
                      <Dropdown.Item href="#Home Improvements">
                        Home Improvements
                      </Dropdown.Item>
                      <Dropdown.Item href="#Vacation">Vacation</Dropdown.Item>
                      <Dropdown.Item href="#Other Emergencies">
                        Other Emergencies
                      </Dropdown.Item>
                      <Dropdown.Item href="#Others...">Others...</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Col>

              <Col className="text-left">
                <Button
                  onClick={this.next}
                  variant="primary"
                  className="w-50 py-2"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </Container>
          <Container
            className="slide-4"
            data-step="3"
            id="preApplicationSlide4"
          >
            <h1 className="h1-header pt-4  text-white text-center">State?</h1>
            <Row className="text-center mt-5">
              <Col className="text-right">
                <Button
                  onClick={this.previous}
                  variant="primary"
                  className="w-50 py-2 prev-btn"
                >
                  Prev
                </Button>
              </Col>
              <Col
                xs={{ order: "first", span: 12 }}
                className="text-right mb-5"
              >
                <Col lg={7} className="mx-auto">
                  <Dropdown
                    className="text-center"
                    onSelect={this.updateDropDown2}
                  >
                    <Dropdown.Toggle
                      defaultValue=""
                      variant="success"
                      id="dropdown-basic2"
                    >
                      Select one
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#AL-Alabama">Alabama</Dropdown.Item>
                      <Dropdown.Item href="#AZ-Arizona">Arizona</Dropdown.Item>
                      <Dropdown.Item href="#CA-California">
                        California
                      </Dropdown.Item>
                      <Dropdown.Item href="#CO-Colorado">
                        Colorado
                      </Dropdown.Item>
                      <Dropdown.Item href="#DE-Delaware">
                        Delaware
                      </Dropdown.Item>
                      <Dropdown.Item href="#DC-District of Columbia"></Dropdown.Item>
                      <Dropdown.Item href="#FL-Florida">Florida</Dropdown.Item>
                      <Dropdown.Item href="#HI-Hawaii">Hawaii</Dropdown.Item>
                      <Dropdown.Item href="#ID-Idaho">Idaho</Dropdown.Item>
                      <Dropdown.Item href="#IL-Illinois">
                        Illinois
                      </Dropdown.Item>
                      <Dropdown.Item href="#IN-Indiana">Indiana</Dropdown.Item>
                      <Dropdown.Item href="#IA-Iowa">Iowa</Dropdown.Item>
                      <Dropdown.Item href="#KS-Kansas">Kansas</Dropdown.Item>
                      <Dropdown.Item href="#KY-Kentucky">
                        Kentucky
                      </Dropdown.Item>
                      <Dropdown.Item href="#LA-Louisiana">
                        Louisiana
                      </Dropdown.Item>
                      <Dropdown.Item href="#ME-Maine">Maine</Dropdown.Item>
                      <Dropdown.Item href="#MI-Michigan">
                        Michigan
                      </Dropdown.Item>
                      <Dropdown.Item href="#MN-Minnesota">
                        Minnesota
                      </Dropdown.Item>
                      <Dropdown.Item href="#MS-Mississippi">
                        Mississippi
                      </Dropdown.Item>
                      <Dropdown.Item href="#MO-Missouri">
                        Missouri
                      </Dropdown.Item>
                      <Dropdown.Item href="#MT-Montana">Montana</Dropdown.Item>
                      <Dropdown.Item href="#NE-Nebraska">
                        Nebraska
                      </Dropdown.Item>
                      <Dropdown.Item href="#NV-Nevada">Nevada</Dropdown.Item>
                      <Dropdown.Item href="#NM-New Mexico">
                        New Mexico
                      </Dropdown.Item>
                      <Dropdown.Item href="ND-#North Dakota">
                        North Dakota
                      </Dropdown.Item>
                      <Dropdown.Item href="#OH-Ohio">Ohio</Dropdown.Item>
                      <Dropdown.Item href="#OK-Oklahoma">
                        Oklahoma
                      </Dropdown.Item>
                      <Dropdown.Item href="#OR-Oregon">Oregon</Dropdown.Item>
                      <Dropdown.Item href="#RI-Rhode Island">
                        Rhode Island
                      </Dropdown.Item>
                      <Dropdown.Item href="#SC-South Carolina">
                        South Carolina
                      </Dropdown.Item>
                      <Dropdown.Item href="#TN-Tennessee">
                        Tennessee
                      </Dropdown.Item>
                      <Dropdown.Item href="#UT-Utah">Utah</Dropdown.Item>
                      <Dropdown.Item href="#WA-Washington">
                        Washington
                      </Dropdown.Item>
                      <Dropdown.Item href="#WI-Wisconsin">
                        Wisconsin
                      </Dropdown.Item>
                      <Dropdown.Item href="#WY-Wyoming">Wyoming</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Col>

              <Col className="text-left">
                <Button
                  onClick={this.redirection}
                  variant="primary"
                  className="w-50 py-2"
                >
                  Continue Application
                </Button>
              </Col>
            </Row>
          </Container>
        </Slider>{" "}
        {/*
        <div style={{ textAlign: "center" }}>
          <button className="button" onClick={this.previous}>
            Previous
          </button>
          <button className="button" onClick={this.next}>
            Next
          </button>
        </div> */}
      </div>
    )
  }
}
