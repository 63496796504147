import React, { useEffect, useState } from "react"
import { Container, Button, Col, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import "./Section2.scss"
import svgScroll from "svg-scroll"

export default function Section2() {
  useEffect(() => {
    var wrappedElement = new svgScroll.ScrollWrapper("#my-svg-path")
  }, [])
  return (
    <Container fluid className="firstcont py-xl-6 section-2-bg">
      <Container fluid="xl" className="py-xl-6">
        <Row>
          <Col
            lg={{ order: "last" }}
            md={{ order: "first" }}
            className="pl-xl-4"
          >
            <div id="my-svg-path"></div>
            <h1 className="h1-header text-center mb-5">
              Pave Credit is an easy way to borrow money.
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={9} className="text-center d-flex justify-content-center">
            <StaticImage
              src="../../../../images/process.png"
              alt="Jump Mobile App"
              className="d-none d-sm-block"
              placeholder="blurred"
            />
            <StaticImage
              src="../../../../images/process-mobile.png"
              alt="Jump Mobile App"
              className="d-sm-none"
              placeholder="blurred"
            />
          </Col>
          <div className="col-12 text-center mt-5 pt-1 d-sm-none">
            <sup className="font-italic">
              Except due to inactivity and no outstanding balance. See the line
              of credit agreement for more details.
            </sup>
          </div>
        </Row>
        <Row className="pt-5">
          <Col className="text-center">
            <Button
              variant="seconday"
              className="w-25 p-3 "
              onClick={() => {
                window.location.href = "https://application.pavecredit.com/"
                return true
              }}
            >
              Apply Now
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
