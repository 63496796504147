/*
import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import withLocation from "../components/withLocation"

import CustomBtn from "../components/CustomBtn"

const IndexPage = ({ search }) => {
  const { template } = search
  return (
    <Layout>
      <SEO title="Home" />
      <h1 className={template}>Hi Shourya 2</h1>
      <p>Welcome to your new Gatsby site.</p>
      <CustomBtn variant={template}>Hola</CustomBtn>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </Layout>
  )
}
export default withLocation(IndexPage)
*/

import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"

import Section1 from "../components/Layout/homePage/Section1/Section1"
import Section2 from "../components/Layout/homePage/Section2/Section2"
import Section3 from "../components/Layout/homePage/Section3/Section3"
import Section4 from "../components/Layout/homePage/Section4/Section4"
import Section5 from "../components/Layout/homePage/Section5/Section5"
import withLocation from "../components/withLocation"
import NavBar from "../components/Layout/navBar/NavBar"
import Footer from "../components/Layout/Footer"

//import PreApplicationSlider from "../components/Extras/PreApplicationSlider/PreApplicationSlider"

const sections = [Section1, Section2, Section3, Section4, Section5]

function FecthSectionsOrder() {
  const [loading, setLoading] = useState(true)
  const [fetchedData, setFectchedData] = useState(null)

  useEffect(() => {
    /*
    fetch("http://69.61.83.229:8181/wp-json/wp/v2/pages")
      .then(response => {
        return response.json()
      })
      .then(json => {
        setLoading(false)
        setFectchedData(json)
      })*/
  }, [])
  //console.log(fetchedData)
  /*
  if (fetchedData) {
    const greetings = fetchedData.filter(
      greeting =>
        greeting.slug === "order-sections-container" ||
        greeting.slug === "section-5-order" ||
        greeting.slug === "section-4-image" ||
        greeting.slug === "section-3-header"
    )
    //  console.log(greetings)
    //console.log(greetings[0].content.rendered)
    //console.log(greetings)
    return greetings
  } else {
    return "Loading"
  }*/
}

const HomePage = ({ search }) => {
  //console.log(search)
  /*
  let orderList = [2, 3, 4, 5]
  if (search.gps) {
    //console.log(FecthSectionsOrder())
    let tales = FecthSectionsOrder()
    if (tales != "Loading") {
      let newSectionOrder = tales[3].content.rendered.split`,`.map(x => +x)
      //orderList = tales
      let section5Order = tales[2].content.rendered

      let section4ImageOption = Number(tales[1].content.rendered)

      let section3HeaderOption = tales[0].content.rendered

      const list = newSectionOrder.map(index => {
        const Component = sections[index - 1]
        if (index === 5) {
          return <Component order={section5Order} />
        }
        if (index === 4) {
          return <Component imageOption={section4ImageOption} />
        }
        if ((index = 3)) {
          return <Component header={section3HeaderOption} />
        }

        return <Component />
      })

      return (
        <Container fluid className="home-container p-0">
          <Section1 /> {list}{" "}
        </Container>
      )
    } else {
      return <Section1 />
    }
  } else {*/
  return (
    <>
      <Container fluid className="home-container principal-page p-0">
        <NavBar />
        <Section1 />
        <Section2 />
        <Section4 />
        <Section5 />
      </Container>
      <Footer />
    </>
  )
}
//}

export default withLocation(HomePage)
