import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Media, Col, Row, Button } from "react-bootstrap"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import "./Section5.scss"

export default function Section5(props) {
  // let [img0, img1,img2,img3,img4,img5] =["","","","","",""]

  /*
  const img0 = getImage(queryImg0.file)
  const img1 = getImage(queryImg1.file)
  const img2 = getImage(queryImg2.file)
  const img3 = getImage(queryImg3.file)
  const img4 = getImage(queryImg4.file)
  const img5 = getImage(queryImg5.file)
*/

  let CardsOrder = [0, 1, 2, 3, 4, 5]
  let orderCounter = 0
  if (props.order) {
    props.order.split`,`.map(index => {
      CardsOrder[orderCounter] = Number(index)
      orderCounter++
    })
  }
  let featureCards = [
    {
      copy:
        "A line of credit means there’s no need to apply every time you need money. ",
    },
    {
      copy: "Borrow only what you need. ",
    },

    {
      copy: "Quickly and easily access your money anytime you need it. ",
    },

    {
      copy:
        "Receive your funds within two business days of requesting a draw in most cases. ",
    },

    {
      copy: "Pay interest only on what you draw. ",
    },

    {
      copy: "No hidden fees ever.",
    },
  ]
  const imgQuery = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "feature_icons" }
          extension: { eq: "png" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(sizes: "90", width: 90)
            }
          }
        }
      }
    }
  `)
  let flagCounter = 0
  //console.log(imgQuery.allFile.edges)
  imgQuery.allFile.edges.map(imageIndex => {
    //return
    //console.log(getImage(imageIndex.node))
    flagCounter++
    // return (featureCards[flagCounter - 1].image = getImage(imageIndex.node))

    //return (featureCards[flagCounter - 1].image = getImage(imageIndex.node))
  })
  //console.log(CardsOrder[2])

  return (
    <Container fluid className="py-xl-6 section-5">
      <Container fluid="xl" className=" py-xl-6">
        <Row className="text-center d-flex justify-content-center pb-5">
          <h1 className="pt-3 text-center h1-header w-50 ">
            The benefits of a Pave Credit line of credit
          </h1>
        </Row>
        <Row className="justify-content-center">
          <Col lg={{ order: "first", span: 4 }}>
            <Media className="mb-5">
              <StaticImage
                className="media-img"
                src="../../../../images/feature_icons/icon_revolve.png"
                alt="Jump Mobile App"
                placeholder="blurred"
                width={100}
                fit="cover"
              />
              <Media.Body className="align-self-center media-text">
                <p>
                  A line of credit means there’s no need to apply every time you
                  need money.
                </p>
              </Media.Body>
            </Media>

            <Media className="mb-5">
              <StaticImage
                className="media-img"
                src="../../../../images/feature_icons/icon_setup.png"
                alt="Jump Mobile App"
                placeholder="blurred"
                width={100}
              />
              <Media.Body className="align-self-center media-text">
                <p>Borrow only what you need. </p>
              </Media.Body>
            </Media>

            <Media className="mb-5">
              <StaticImage
                className="media-img"
                src="../../../../images/feature_icons/icon_payment_Phone.png"
                alt="Jump Mobile App"
                placeholder="blurred"
                width={100}
              />
              <Media.Body className="align-self-center media-text">
                <p>
                  Quickly and easily access your money anytime you need it.{" "}
                </p>
              </Media.Body>
            </Media>
          </Col>
          <Col lg={{ order: "last", span: 4 }}>
            <Media className="mb-5">
              <StaticImage
                className="media-img"
                src="../../../../images/feature_icons/icon_setup.png"
                alt="Jump Mobile App"
                placeholder="blurred"
                width={100}
              />
              <Media.Body className="align-self-center media-text">
                <p>
                  Receive your funds within two business days of requesting a
                  draw in most cases.{" "}
                </p>
              </Media.Body>
            </Media>
            <Media className="mb-5">
              <StaticImage
                className="media-img"
                src="../../../../images/feature_icons/icon_percent.png"
                alt="Jump Mobile App"
                placeholder="blurred"
                width={100}
              />
              <Media.Body className="align-self-center media-text">
                <p>Pay interest only on what you draw. </p>
              </Media.Body>
            </Media>
            <Media className="mb-5">
              <StaticImage
                className="media-img"
                src="../../../../images/feature_icons/icon_no_fees_02.png"
                alt="Jump Mobile App"
                placeholder="blurred"
                width={100}
              />
              <Media.Body className="align-self-center media-text">
                <p>No hidden fees ever.</p>
              </Media.Body>
            </Media>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button
              variant="seconday"
              className="w-50 p-3 "
              onClick={() => {
                window.location.href = "https://application.pavecredit.com/"
                return true
              }}
            >
              Apply Now
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
